
import ImageLoader from "@/assets/scripts/ImageLoader";
import DoubleBlock from "@/components/blocks/Double.vue";
import ContactDetailsTable from "@/components/tables/ContactDetails.vue";

export default {
  name: "FooterLayout",
  props: {
    displayContactDetails: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DoubleBlock,
    ContactDetailsTable,
  },
  data() {
    return {
      currentYear: 0 as number,
      facebookLogo: ImageLoader.loadImageSrc("assets/images/logos/facebook.png") as string,
      facebookLogoActive: ImageLoader.loadImageSrc("assets/images/logos/facebook_active.png") as string,
      linkedinLogo: ImageLoader.loadImageSrc("assets/images/logos/linkedin.png") as string,
      linkedinLogoActive: ImageLoader.loadImageSrc("assets/images/logos/linkedin_active.png") as string,
      githubLogo: ImageLoader.loadImageSrc("assets/images/logos/github.png") as string,
      githubLogoActive: ImageLoader.loadImageSrc("assets/images/logos/github_active.png") as string,
    };
  },
  methods: {
    setCurrentYear(): void {
      (this as any).currentYear = new Date().getFullYear();
    },
  },
  mounted() {
    (this as any).setCurrentYear();
  },
};
